import React, { useState } from 'react';
// import './pair-up.scss'

let personCounter = 0;

type PeopleType = {
  key: string;
  name: string;
  optional: boolean;
}

type RoundType = {
  // TODO define round type (how many people matched together? min? max?)
}

export default function PairUp() {

  const [ people, setPeople ] = useState([] as PeopleType[]);

  const handleNameChange = (index: number, newName: string) => {
    setPeople((prevPeople) => {
      const newPeople = [...prevPeople];
      newPeople[index].name = newName;
      return newPeople;
    });
  };

  const handleAddPerson = () => {
    const key = 'person' + ++personCounter;
    setPeople((prevPeople: PeopleType[]) => [...prevPeople, { key, name: '', optional: false }]);
  };

  return (
    <div className="tool-pairup">
      <h2>Pair-Up</h2>

      {/* ** List of Participants ** */}
      <h3>Participants</h3>
      {/* Existing ones */}
      { people.map((p, index) => 
        <div className="people-input" key={p.key}>
          <input
            className="people-name"
            value={p.name}
            onChange={e => handleNameChange(index, e.target.value)}
            placeholder="name"
            />
        </div>)
      }
      {/* Add person */}
      <button id="tool-pairup-add-person" onClick={handleAddPerson}>Add Person</button>


      {/* TODO: add individual Rounds here, and let create new ones, with algorithmic solution to matching people properly */}
    </div>
  );
}
