import React from 'react';
// import './about.scss'

export default function AboutPage() {
  return (
    <div className="page-about">
        <h2>About</h2>
        <p>
            This website containts a number of small, hopefully useful tools, particularly for people interested in rationality and effective altruism.
        </p>
        <p>
            My plan with these tools was to have an easy way to provide simple solutions for small recurring problems, or enable certain types of events
            within the rationality/EA community that would otherwise be more difficult to pull off.
        </p>
        <p>
            Everything on this page is usable for free and no attribution is required. If you have any feedback or would like to see a new tool of some
            kind, don't hesitate to contact me. Email address can be found in the Impressum.
        </p>
        <p>
            Use the tools on this website at your own risk. I think they're pretty much harmless, but who knows what you're going to do with them,
            hence this very useful disclaimer. :)
        </p>
    </div>
  );
}
