import React, { ChangeEvent, useEffect, useState } from 'react';
import getToolStorage from '../toolStorage';
import './reflection-workshop.scss'

const defaultYoutubeLink = "Eg4niYQCCVc" // ?autoplay=1&controls=0 <- automatically appended

const defaultSlidesContent = `What has been your biggest win in 2023?
What is your favorite memory from 2023?
What has been the biggest positive change for you?
What is the biggest risk you've taken?
What has been your biggest challenge?
What habits / beliefs from this year will you hold on to in 2024?
Which will you let go of?
Write down 5 words that reflect you and your current situation, and 5 words that you could reflect a better version of you and your situation in the future (they can overlap!).
What patterns, habits or beliefs have held you back the most in 2023?
The most effective gratitude practice is receiving gratitude. Think about a time someone was grateful for something you did. Enjoy the fuzzies!
Keeping in mind the thing that hold you back, take a moment to visualize 1 to 3 steps you can take to move in the right direction. When will you make time for these actions?
When you think about next year, what are you most excited about?
If you could pursue only one goal, what would you choose to do? Is your current state in line with this priority? If not, why?
Have a great 2024`

const storage = getToolStorage("reflectionWorkshop");

export default function ReflectionWorkshop() {

  const [initialized, setInitialized] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [slidesContent, setSlidesContent] = useState("");

  const handleVideo = (event: ChangeEvent) => {
    setVideoUrl((event.target as HTMLInputElement).value);
  }

  const handleSlides = (event: ChangeEvent) => {
    setSlidesContent((event.target as HTMLTextAreaElement).value);
  }

  const storeReflectionContent = () => {
    if (!initialized) {
        return;
    }
    storage.setItem("youtube", videoUrl);
    storage.setItem("slides", slidesContent);
  }

  useEffect(() => {
    storeReflectionContent();
  }, [videoUrl, slidesContent])

  useEffect(() => {
    if (initialized) {
        return;
    }
    const video = storage.getItem("youtube");
    setVideoUrl(video || defaultYoutubeLink);
    const content = storage.getItem("slides");
    setSlidesContent(content || defaultSlidesContent);
    setInitialized(true);
  }, [])

  return (
    <div className="tool-reflection-workshop">
        <h1>Reflection Workshop Template</h1>
        <h2>Background Video ID</h2>
        <input
            id="reflection-workshop-video-input"
            type="text"
            placeholder="Link to an embeddable youtube video"
            value={videoUrl}
            onChange={handleVideo}
        ></input>
        <h2>Slides</h2>
        <p>Each line represents one slide.</p>
        <textarea
            id="reflection-workshop-slides-input"
            placeholder="The text inside this box will show up as the slides content of the workshop"
            value={slidesContent}
            onChange={handleSlides}
        ></textarea>
        <p style={{fontStyle: "italic"}}>Please note: this data is only cached in your browser. If you want to reuse it in the future,
            please make sure to back up your data above manually, as we don't have any kind of account system in place here at this point.</p>
        <p>When running the slideshow, use your arrowkeys to switch between "slides" (/lines).</p>
        <p>Hint: If you want to start with an empty slide, just begin the slides content above with an empty line.</p>
        <p>Hint: You can write \n into your text to enforce custom line breaks within a slide.</p>

        <div className="button-wrapper">
            <a href="/tools/reflection-workshop.html"><button>Run Slideshow</button></a>
        </div>
    </div>
  );
}
