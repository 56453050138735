
export default function getToolStorage(name: string): Storage {
    const keys: string[] = [];
    const obj = {
        getItem(key: string): string | null {
            return localStorage.getItem(getFullKey(key));
        },
        setItem(key: string, value: string): void {
            const fullKey = getFullKey(key);
            if (!keys.includes(key)) {
                keys.push(key);
                obj.length = keys.length;
            }
            localStorage.setItem(fullKey, value);
        },
        removeItem(key: string): void {
            localStorage.removeItem(getFullKey(key));
            const index = keys.indexOf(key);
            keys.splice(index, 1);
            obj.length = keys.length;
        },
        clear() {
            while (keys.length) {
                obj.removeItem(keys[0]);
            }
        },
        length: 0,
        key: (i: number) => keys[i] ?? null,
    }
    return obj;

    function getFullKey(key: string): string {
        return "tool:" + name + "//" + key;
    }
}