import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss'

function Header() {
  return (
    <div className="app-header">
      <header>
        <h1><a href="/">Rational Tools</a></h1>

        <div className="space"></div>

        <nav>
          <button>
            <Link to="/">Tools</Link>
          </button>
          <button>
            <Link to="/about">About</Link>
          </button>
        </nav>
      </header>
    </div>
  );
}

export default Header;
