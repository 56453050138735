import React, { ChangeEvent, useEffect, useState } from 'react';
import './reward-randomizer.scss';

const defaultRewards = `100 Favorite Music 🎵
100 Icecream 🍦
100 Hot Tub 🛁
100 Movie Night 🎬
100 Chocolate 🍫
100 Favorite Snack 🍿
100 Extra Hour of Sleep 💤
100 New Book 📚
100 Order Pizza 🍕
100 Gaming Session 🎮
100 Spa Day 🧖‍♀️
100 Outdoor Adventure 🏞️
100 Cocktail 🍹
50 Joker: Choose Any Reward 🃏`;

const storageKey = "rewardRandomizer";

export default function RewardRandomizer() {
  const [view, setView] = useState<'main' | 'edit' | 'results'>('main');
  const [rewardUnits, setRewardUnits] = useState<number | ''>('');
  const [rewardsList, setRewardsList] = useState<string[]>([]);
  const [results, setResults] = useState<string[]>([]);
  const [initialized, setInitialized] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [showRewards, setShowRewards] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const storedRewards = localStorage.getItem(storageKey);
      setRewardsList(storedRewards ? storedRewards.split('\n') : defaultRewards.split('\n'));
      setInitialized(true);
    }
  }, [initialized]);

  const handleRewardUnitsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRewardUnits(event.target.value ? parseInt(event.target.value) : '');
  };

  const handleRewardsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setRewardsList(event.target.value.split('\n'));
  };

  const selectRandomReward = (rewards: { likelihood: number, text: string }[]) => {
    const totalLikelihood = rewards.reduce((sum, reward) => sum + reward.likelihood, 0);
    let randomValue = Math.random() * totalLikelihood;
    for (const reward of rewards) {
      if (randomValue < reward.likelihood) {
        return reward.text;
      }
      randomValue -= reward.likelihood;
    }
    return null;
  };

  const cashInRewards = () => {
    const rewards = rewardsList.map(reward => {
      const [likelihood, ...rewardText] = reward.split(' ');
      return { likelihood: parseInt(likelihood), text: rewardText.join(' ') };
    });
    const units = typeof rewardUnits === 'number' ? rewardUnits : 0;
    const wonRewards: string[] = [];
    for (let i = 0; i < units; i++) {
      if (Math.random() < 0.01) {
        const reward = selectRandomReward(rewards);
        if (reward) {
          wonRewards.push(reward);
        }
      }
    }
    setResults(wonRewards);
    setView('results');
    setShowResults(false);
    setShowRewards(false);
  };

  const applyRewards = () => {
    localStorage.setItem(storageKey, rewardsList.join('\n'));
    setView('main');
  };
  
  const resetRewards = () => {
    if (window.confirm("Are you sure you want to reset to default rewards?")) {
      setRewardsList(defaultRewards.split('\n'));
      localStorage.removeItem(storageKey);
    }
  };
  

  const cancelEdit = () => {
    setView('main');
  };

  // Adjust the delays
useEffect(() => {
    if (view === 'results') {
      const timer1 = setTimeout(() => {
        setShowResults(true);
        const timer2 = setTimeout(() => {
          setShowRewards(true);
          const timer3 = setTimeout(() => {
            document.querySelector('.back-button')?.classList.add('show');
          }, results.length * 500 + 500); // Delay for back button
          return () => clearTimeout(timer3);
        }, 1000);
        return () => clearTimeout(timer2);
      }, 1000);
      return () => clearTimeout(timer1);
    }
  }, [view, results]);

  // Render results with fade-out and slide-up effect// Adjust the delays
useEffect(() => {
  if (view === 'results') {
    const timer1 = setTimeout(() => {
      setShowResults(true);
      const timer2 = setTimeout(() => {
        setShowRewards(true);
      }, 1500); // Adjusted delay
      return () => clearTimeout(timer2);
    }, 1500); // Adjusted delay
    return () => clearTimeout(timer1);
  }
}, [view, results]);

// Render results with fade-out and slide-up effect
const renderResults = () => {
    return (
      <>
        <div className={`initial-message-container ${showResults ? 'fade-out' : ''}`}>
          <p className="initial-message">Your reward is...</p>
        </div>
        {showResults && (
          <div className="results">
            {results.length === 0 ? (
              <p className="fade-in">Unlucky! No reward this time.</p>
            ) : (
              <>
                <p className="fade-in">You got {results.length} rewards:</p>
                {results.map((reward, index) => (
                  <div key={index} className={`reward-item ${showRewards ? 'show' : ''}`} style={{ transitionDelay: `${index * 500}ms` }}>{reward}</div>
                ))}
              </>
            )}
          </div>
        )}
        <button className="back-button" onClick={() => { setView('main'); setRewardUnits(''); }}>Back</button>
      </>
    );
  };

  useEffect(() => {
    if (view === 'results') {
      const timer1 = setTimeout(() => {
        setShowResults(true);
        const timer2 = setTimeout(() => {
          setShowRewards(true);
        }, 1000 + results.length * 500);
        return () => clearTimeout(timer2);
      }, 1500);
      return () => clearTimeout(timer1);
    }
  }, [view, results]);

  return (
    <div className="tool-reward-randomizer">
      <h1>Reward Randomizer</h1>
      {view === 'main' && (
        <>
          <div className="input-group">
            <input
              type="number"
              placeholder="reward units"
              value={rewardUnits}
              onChange={handleRewardUnitsChange}
              style={{ height: '40px' }}
            />
            <button onClick={cashInRewards}>Go</button>
          </div>
          <h2>How it works:</h2>
          <p>
            Enter the number of reward units you have earned and click "Go" to see if you win any rewards! 
            Each unit represents a chance to win a reward, with 100 units giving you on average one reward (but not always!). 
            Decide on your own how to use this tool and how many points to award yourself. For example, you could use this to motivate yourself to build a new habit or to do some undesirable work.
          </p>
          <p>
            <a href="#" onClick={() => setView('edit')}>Customize your own rewards</a>, and they will be stored in your browser for future use.
          </p>
        </>
      )}
      {view === 'edit' && (
        <>
            <textarea
            value={rewardsList.join('\n')}
            onChange={handleRewardsChange}
            />
            <p>
            Each reward should be on a new line, starting with a number indicating its likelihood (higher numbers mean more likely). Usage of emojis is optional.
            The likelihood is only relative, so it doesn't control if you will win rewards at all (this is always 1 per 100 reward units in expectation), but how likely any one of them is to be chosen relative to each other.
            </p>
            <button onClick={applyRewards}>Apply</button>
            <button onClick={cancelEdit}>Cancel</button>
            <button onClick={resetRewards}>Reset</button>
        </>
      )}
      {view === 'results' && renderResults()}
    </div>
  );
}