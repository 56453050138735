import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

function Footer() {
  return (
    <div className="app-footer">
      <footer>
        <Link to="/impressum">Impressum</Link>
        <Link to="/datenschutz">Datenschutz | Data Protection</Link>
      </footer>
    </div>
  );
}

export default Footer;
